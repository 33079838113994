import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledBadge = styled('span')(({ theme, status }) => ({
  backgroundColor: status === 'Approved' ? 'green' : status === 'Rejected' ? 'red' : 'orange',
  color: 'white',
  padding: '0.25em 0.5em',
  borderRadius: '4px',
}));

const calculateTotalFees = (fees) => {
  if (!fees) return 0;
  const { registration, tuition, textbooks, parking, laboratory } = fees;
  return (registration || 0) + (tuition || 0) + (textbooks || 0) + (parking || 0) + (laboratory || 0);
};

const formatCurrency = (amount) => {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const allColumns = [
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'requestor', headerName: 'Requestor', flex: 1, valueGetter: (params) => params.name || 'N/A' },
  { field: 'dateOfSubmission', headerName: 'Date of Submission', flex: 1, valueGetter: (date) => date ? new Date(date).toLocaleDateString() : 'N/A',  sortComparator: (v1, v2) => new Date(v2) - new Date(v1) },
  { field: 'schoolName', headerName: 'School Name', flex: 1 },
  { field: 'courses', headerName: 'Courses Selected', flex: 1, valueGetter: (courses) => courses ? courses.map(course => course.courseName).join(', ') : 'N/A' },
  { field: 'totalFees', headerName: 'Total Fees (Pre-Approved)', flex: 1, 
    renderCell: (params) => <span>{formatCurrency(calculateTotalFees(params.row.fees))}</span>,
    valueGetter: (value, row) => {
      return calculateTotalFees(row.fees);
    }
  },
  { field: 'manager', headerName: 'Manager', flex: 1,  
    valueGetter: (value, row) => {
      return row.requestor?.managerName || 'N/A';
    } 
  },
  { field: 'managerStatus', headerName: 'Manager Status', flex: 1, 
    renderCell: (params) => <StyledBadge status={params.row.approvalStatus?.managerStatus}>{params.row.approvalStatus?.managerStatus}</StyledBadge>,
    valueGetter: (value, row) => {
      return row.approvalStatus?.managerStatus;
    }
  },
  { field: 'dateOfManagerApproval', headerName: 'Manager Review Date', flex: 1, 
    valueGetter: (value, row) => {
      return row.approvalStatus.dateOfManagerApproval ? new Date(row.approvalStatus.dateOfManagerApproval).toLocaleDateString() : '1/1/1900';
    },
    sortComparator: (v1, v2) => new Date(v2) - new Date(v1),
    renderCell: (params) => <span>{params.row.approvalStatus.dateOfManagerApproval ? new Date(params.row.approvalStatus.dateOfManagerApproval).toLocaleDateString() : ''}</span>
  },
  { field: 'hrStatus', headerName: 'HR Status', flex: 1, 
    renderCell: (params) => params.row.approvalStatus.managerStatus !== "Rejected" ? <StyledBadge status={params.row.approvalStatus?.hrStatus}>{params.row.approvalStatus?.hrStatus}</StyledBadge> : '',
    valueGetter: (value, row) => {
      return row.approvalStatus.managerStatus !== "Rejected" ? row.approvalStatus?.hrStatus : '';
    }
  },
  { field: 'dateOfHrApproval', headerName: 'HR Review Date', flex: 1, 
    valueGetter: (value, row) => {
      return row.approvalStatus.managerStatus !== "Rejected" ? row.approvalStatus.dateOfHrApproval ? new Date(row.approvalStatus.dateOfHrApproval).toLocaleDateString() : '1/1/1900' : '1/1/1900';
    },
    sortComparator: (v1, v2) => new Date(v2) - new Date(v1),
    renderCell: (params) => <span>{params.row.approvalStatus.dateOfHrApproval ? new Date(params.row.approvalStatus.dateOfHrApproval).toLocaleDateString() : ''}</span> 
  },
];

const mobileColumns = [
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'dateOfSubmission', headerName: 'Date of Submission', flex: 1, valueGetter: (date) => date ? new Date(date).toLocaleDateString() : 'N/A',  sortComparator: (v1, v2) => new Date(v2) - new Date(v1) },
  { field: 'managerStatus', headerName: 'Manager Status', flex: 1, 
    renderCell: (params) => <StyledBadge status={params.row.approvalStatus?.managerStatus}>{params.row.approvalStatus?.managerStatus}</StyledBadge>,
    valueGetter: (value, row) => {
      return row.approvalStatus?.managerStatus;
    }
  },
  { field: 'hrStatus', headerName: 'HR Status', flex: 1, 
    renderCell: (params) => params.row.approvalStatus.managerStatus !== "Rejected" ? <StyledBadge status={params.row.approvalStatus?.hrStatus}>{params.row.approvalStatus?.hrStatus}</StyledBadge> : '',
    valueGetter: (value, row) => {
      return row.approvalStatus.managerStatus !== "Rejected" ? row.approvalStatus?.hrStatus : '';
    }
  }
];

const RequestsTable = ({ requests, onRowClick }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const columns = isMobile ? mobileColumns : allColumns;

  return (
    <DataGrid
      rows={requests}
      columns={columns}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      onRowClick={onRowClick}
      initialState={{
        pagination: {
            paginationModel: {
              pageSize: 25,
            },
        },
        sorting: {
          sortModel: [{ field: 'dateOfSubmission', sort: 'asc' }],
        },
      }}
    />
  );
};

export default RequestsTable;
