import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress, Box, Container, Typography } from '@mui/material';
import RequestsTable from './RequestsTable';
import { Helmet } from 'react-helmet';

const HrRejectedRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const apiToken = useSelector((state) => state.user.apiToken);
  const apiRootUrl = process.env.REACT_APP_TUITION_REMIBURSEMENT_API_ROOTURL;

  useEffect(() => {
    if (!user) {
      navigate('/'); // Redirect to login if user is not logged in
      return;
    }
    const fetchRequests = async () => {
      try {
        const response = await axios.get(`${apiRootUrl}/api/requests/get-hr-rejected-requests`, {
          headers: {
            'Authorization': `Bearer ${apiToken}`
          }
        });
        setRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching requests:', error);
        setLoading(false);
      }
    };

    fetchRequests();
  }, [user, apiToken]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xxl">
       <Helmet>
        <title>All Requests</title>
        <meta name="description" content="Tuition Reimbursment - HR Rejected" />
      </Helmet>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Requests Rejected by HR
        </Typography>
        <Box sx={{ height: '75vh', width: '100%' }}>
          <RequestsTable 
            requests={requests} 
            onRowClick={(params) => navigate(`/requests/${params.id}`)} 
          />
        </Box>
      </Box>
    </Container>
  );
};

export default HrRejectedRequests;