// src/UserDetails/msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const getRedirectUri = () => {
  return "https://dev-tuition-reimbursement.pharmavite.com";
};

const msalConfig = {
  auth: {
    clientId: "e8fbcc85-627d-4be0-931b-d5b8044300f0", // Replace with your GraphAPI client ID
    authority: "https://login.microsoftonline.com/59034451-9b9d-4095-95cb-cb280da28cc6", // Replace with your tenant ID
    redirectUri: getRedirectUri(),
    
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
