// src/RequestForm.js
import React, { useState, useEffect } from "react";
import { Container, TextField, Grid, Typography, Button, Switch, FormControlLabel, Box, Paper, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import numeral from 'numeral';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Helmet } from 'react-helmet';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[3],
  }));
  
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, #d96704, #e88c2a)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundImage: 'linear-gradient(to right, #e88c2a, #d96704)',
  },
}));

const RequestFormSomeoneElse = () => {
    const [signOffStates, setSignOffStates] = useState([false, false, false, false, false]);
    const navigate = useNavigate();
    const [numCourses, setNumCourses] = useState(5);
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formValues, setFormValues] = useState({
    schoolName: '',
    courses: Array(5).fill({ courseName: '', startDate: '', endDate: '', classroom: false }),
    fees: {
      registration: '0.00',
      tuition: '0.00',
      textbooks: '0.00',
      parking: '0.00',
      laboratory: '0.00',
    },
    clauses: {
        reimbursementLimit: false,
        employed90Days: false,
        otherEducationBenefits: false,
        employmentCondition: false,
        globalAgreement: false,
      },
    
  });

  const userDetails = useSelector((state) => state.user.user);
  const managerDetails = useSelector((state) => state.user.manager);
  const requestForUser = useSelector((state) => state.user.newRequestSomeoneElseSelection)
  const requestForUserManager = useSelector((state) => state.user.newRequestSomeoneElseSelectionManager)
  const apiToken = useSelector((state) => state.user.apiToken);
  const apiRootUrl = process.env.REACT_APP_TUITION_REMIBURSEMENT_API_ROOTURL;
  const [total, setTotal] = useState('0.00');
  const [isFormValid, setIsFormValid] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (!userDetails) {
      navigate('/'); // Redirect to login if user is not logged in
      return;
    }
    const sum = Object.values(formValues.fees).reduce((acc, fee) => {
      // Remove commas and parse the fee as a float
      const numericFee = parseFloat(fee.replace(/,/g, '') || 0);
      return acc + numericFee;
    }, 0);
    setTotal(numeral(sum).format('0,0.00'));
  }, [formValues.fees]);

  useEffect(() => {
    if (requestForUser === null) {
      navigate('/user-search');
    }
  }, [requestForUser, navigate]);
  
  useEffect(() => {
    // Retrieve user details from session storage
    const storedUserDetails = sessionStorage.getItem("userDetails");
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      setUserName(userDetails.displayName);
    }
  }, []);

    // Call validate when the form values change
    useEffect(() => {
      validate();
    }, [formValues]);

  const handleNumCoursesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumCourses(value);
  
    // Adjust the courses array length based on the new number of courses
    setFormValues((prevValues) => ({
      ...prevValues,
      courses: Array(value).fill({ courseName: '', startDate: '', endDate: '', classroom: false }),
    }));
  };

   const handleFeeChange = (feeType) => (value) => {
    // Remove commas before storing the value
    const numericValue = value.replace(/,/g, '');
    setFormValues((prevValues) => ({
      ...prevValues,
      fees: {
        ...prevValues.fees,
        [feeType]: numericValue,
      },
    }));
  };

  const handleCourseChange = (index, field) => (e) => {
    const newCourses = formValues.courses.map((course, i) => (
      i === index ? { ...course, [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } : course
    ));
    setFormValues((prevValues) => ({
      ...prevValues,
      courses: newCourses,
    }));
  };

  const handleSchoolNameChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      schoolName: e.target.value,
    }));
  };

  const handleClauseChange = (clause) => (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      clauses: {
        ...prevValues.clauses,
        [clause]: e.target.checked,
      },
    }));
  };

  const validate = () => {
    let tempErrors = {};

    //clause validation
    if (!formValues.clauses.reimbursementLimit) {
      tempErrors.reimbursementLimit = 'You must accept the reimbursement limit clause.';
    }
    if (!formValues.clauses.employed90Days) {
      tempErrors.employed90Days = 'You must confirm employment for the last 90 days.';
    }
    if (formValues.clauses.otherEducationBenefits) {
      tempErrors.otherEducationBenefits = 'You must confirm you are not receiving other educational benefits.';
    }
    if (!formValues.clauses.employmentCondition) {
      tempErrors.employmentCondition = 'You must accept the employment condition clause.';
    }
    if (!formValues.clauses.globalAgreement) {
      tempErrors.globalAgreement = 'You must accept the global agreement clause.';
    }

    // Course validation
    formValues.courses.forEach((course, index) => {
      if (!course.courseName || !course.startDate || !course.endDate) {
        tempErrors[`course${index}`] = 'All course details must be filled out.';
      }
    });

    // School Name validation
    if (!formValues.schoolName) {
      tempErrors.schoolName = 'The name of the school is required.';
    }

    setErrors(tempErrors);
    const isValid = Object.keys(tempErrors).length === 0;
    setIsFormValid(isValid);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const now = new Date();
    const timestamp = now.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
    // Format the data as needed
    const formattedData = {
      ...formValues,
      fees: Object.fromEntries(Object.entries(formValues.fees).map(([key, value]) => [key, parseFloat(value)])),
      title: userName + " Tuition Request on " + timestamp
    };

    const token = apiToken

      const payload = {
        schoolName: formValues.schoolName,
        courses: formValues.courses,
        fees: Object.fromEntries(Object.entries(formValues.fees).map(([key, value]) => [key, parseFloat(value)])),
        clauses: formValues.clauses,
        requestor: {
          email: requestForUser.mail,
          name: requestForUser.displayName,
          entraId: requestForUser.id,
          employeeId: Number(requestForUser.employeeId),
          department: requestForUser.department,
          jobTitle: requestForUser.jobTitle,
          managerName: requestForUserManager.displayName,
          managerEmail: requestForUserManager.mail,
          managerEntraId: requestForUserManager.id,
          managerEmployeeId: Number(requestForUserManager.onPremisesExtensionAttributes.extensionAttribute3)
        },
        approvalStatus: {
          managerStatus: "Pending",
          HRStatus: "Pending"
        },
        title: `${requestForUser.displayName} Tuition Request on ${new Date().toLocaleString()}, Requested By ${userDetails.displayName}`,
        dateOfSubmission: new Date().toISOString()
      };

    if(validate()){
      try {
        const response = await axios.post(`${apiRootUrl}/api/Requests`, payload, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiToken}`
            }
        });

        setSnackbarMessage('Submission Successful!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        const returnedId = response.data.id
        navigate(`/requests/${returnedId}`);


        } catch (error) {
        console.error('Error making request:', error.response ? error.response.data : error.message);
        console.error('Error making request:', error.response ? error.response.data : error.message);
        setSnackbarMessage('Error making request. Please contact an IT Administrator.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Error making request. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      
    }
  }

  const handleSwitchChange = (index) => (event) => {
    const newSignOffStates = [...signOffStates];
    newSignOffStates[index] = event.target.checked;
    setSignOffStates(newSignOffStates);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xl">
      {requestForUser && <Helmet>
        <title>{`New Request For ${requestForUser.displayName}`}</title>
        <meta name="description" content={`Tuition Reimbursement - Request For ${requestForUser.displayName}`} />
      </Helmet>}
        { requestForUser && (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          New Tuition Reimbursement Pre-Approval Request
        </Typography>
        
        
          <StyledPaper sx={{ mb: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            User Details For Request
          </Typography>
          <Grid container spacing={2}>
          
            <Grid item xs={12} sm={6}>
            
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                value={requestForUser.displayName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                value={requestForUser.mail}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Department"
                variant="outlined"
                margin="normal"
                value={requestForUser.department}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Job Title"
                variant="outlined"
                margin="normal"
                value={requestForUser.jobTitle}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Manager Name"
                variant="outlined"
                margin="normal"
                value={requestForUserManager.displayName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Manager Email"
                variant="outlined"
                margin="normal"
                value={requestForUserManager.mail}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            
          </Grid>
          </StyledPaper>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <StyledPaper>
            <Typography variant="h6" component="h2" gutterBottom>
                    School and Class Details
                </Typography>
                
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                
                <TextField
                   fullWidth
                   label="Name of School"
                   variant="outlined"
                   margin="normal"
                   value={formValues.schoolName}
                   onChange={handleSchoolNameChange}
                /> </Grid>
                  <Grid item xs={12} sm={4} container  justifyContent="flex-end">
                <InputLabel id="course-count-label" style={{marginRight: '10px'}}>Number of Courses</InputLabel>
                <Select
                    value={numCourses}
                    onChange={(event) => {
                      const value = parseInt(event.target.value, 10);
                      setNumCourses(value);
                  
                      setFormValues((prevValues) => {
                          const existingCourses = prevValues.courses.slice(0, value); // keep the current courses
                          const newCourses = Array(Math.max(value - existingCourses.length, 0)).fill({ courseName: '', startDate: '', endDate: '', classroom: false }); // fill with new courses if needed
                          return {
                              ...prevValues,
                              courses: existingCourses.concat(newCourses),
                          };
                      });
                    }}
                  >
                    {[1, 2, 3, 4, 5].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  </Grid>
                  </Grid>
                <Box sx={{ mb: 4 }} />
                {formValues.courses.map((course, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <TextField
                          fullWidth
                          label="Course Name"
                          variant="outlined"
                          margin="normal"
                          value={course.courseName}
                          onChange={handleCourseChange(index, 'courseName')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Start Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          value={course.startDate}
                          onChange={handleCourseChange(index, 'startDate')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="End Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          margin="normal"
                          value={course.endDate}
                          onChange={handleCourseChange(index, 'endDate')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={<Switch checked={course.classroom} onChange={handleCourseChange(index, 'classroom')} />}
                        label="Classroom"
                        sx={{ mt: 2 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Box sx={{ mt: 4 }}> 
                 <Grid container spacing={2}>
                 <Grid item xs={12} sm={6}>
                 <Typography variant="h6" component="h2" gutterBottom>
                    Fees
                    </Typography>
                    </Grid>
                </Grid>                 
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <DollarInput label="Registration Fee" value={formValues.fees.registration} onChange={handleFeeChange('registration')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <DollarInput label="Tuition Fee" value={formValues.fees.tuition} onChange={handleFeeChange('tuition')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <DollarInput label="Textbooks Fee" value={formValues.fees.textbooks} onChange={handleFeeChange('textbooks')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <DollarInput label="Parking Fee" value={formValues.fees.parking} onChange={handleFeeChange('parking')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <DollarInput label="Laboratory Fee" value={formValues.fees.laboratory} onChange={handleFeeChange('laboratory')} />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end" alignItems="flex-end">
                    <Typography variant="h6" style={{ color: 'red', fontWeight: 'bold' }}>
                        Total For Pre-Approval: ${total}
                    </Typography>
                    </Grid>
                </Grid>             
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledButton variant="contained" type="submit"  disabled={!isFormValid}>
                    Submit Request
                  </StyledButton>
                </Box>
             
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledPaper>
            <Typography variant="h6" component="h2" gutterBottom>
                    Clauses
                </Typography>
            {[
                { label: "I understand that Pharmavite reimbursement of tuition and expenses is limited to a maximum of $5,250 per calendar year. Check to sign and accept.", key: "reimbursementLimit" },
                { label: "Have you been employed by Pharmavite for the last 90 days?", key: "employed90Days" },
                { label: "Are you receiving educational assistance from the Veteran’s Administration, Pell Grants or any other formal educational aid programs, whether public or private? If so, you are not eligible for reimbursement under this Tuition Reimbursement program. Benefits provided under this program are to cover monies personally paid by the employee.", key: "otherEducationBenefits" },
                { label: "I understand in order to be reimbursed for the above courses, I must still be employed at Pharmavite. In addition, I must receive a passing grade of 'C' or better for all undergraduate or trade school courses. For graduate course(s), I must receive a passing grade of 'B' or better. Check to sign and accept.", key: "employmentCondition" },
                { label: "Agreement Regarding Reimbursement of Education Expenses: In consideration of Pharmavite's offer to pay for and/or reimburse to me certain educational expenses, I agree that, if I voluntarily resign my employment with Pharmavite for any reason on or before one year from the date on which I will complete my classwork, I will pay to Pharmavite an amount equal to the difference between (a) the total monies Pharmavite has paid to others on my behalf and/or reimbursed to me during such one year period for educational expenses and (b) $1,500 ('repayable educational assistance expenses'). I agree that Pharmavite may deduct the amount of such repayable educational assistance expenses from any wages or monies due me at the time of my termination of employment from Pharmavite. I agree to sign, at the request of Pharmavite, a further written consent authorizing Pharmavite to deduct the amount of such repayable educational assistance expenses from my final paycheck. I acknowledge that I have read and understand the terms of this agreement, and that I have freely chosen to agree to these conditions after carefully considering the terms.", key: "globalAgreement" }
                ].map((clause, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" gutterBottom>
                    {clause.label}
                    </Typography>
                    <FormControlLabel
                    control={<Switch checked={formValues.clauses[clause.key]} onChange={handleClauseChange(clause.key)} />}
                    label={formValues.clauses[clause.key] ? "Yes" : "No"}
                    />
                </Box>
                ))}

            </StyledPaper>
          </Grid>
        </Grid>
        </form>
        <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                    '& .MuiSnackbarContent-root': {
                        fontSize: '1.25rem',
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        color: '#fff',
                        boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
                        padding: '16px 24px',
                        borderRadius: '8px',
                    }
                    }}
        >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Box>
        )}
    </Container>
          
  );
};

export default RequestFormSomeoneElse;

const DollarInput = ({ label, value, onChange }) => {
    const handleChange = (e) => {
      const inputValue = e.target.value;
      // Remove non-numeric characters except for the decimal point
      const numericValue = inputValue.replace(/[^0-9.]/g, '');
      // Ensure only one decimal point is allowed
      const validValue = numericValue.split('.').length > 2 ? numericValue.slice(0, -1) : numericValue;
      onChange(validValue);
    };
  
    const handleBlur = () => {
      // Ensure the value is formatted to two decimal places with commas
      const numericValue = parseFloat(value.replace(/,/g, ''));
      const formattedValue = numeral(numericValue).format('0,0.00');
      onChange(formattedValue);
    };
  
    const handleFocus = () => {
      if (value === '0.00') {
        onChange('');
      }
    };
  
    return (
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
        value={value ? `$${value}` : '$0.00'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    );
  };
  